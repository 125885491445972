import * as React from 'react';
import { navigate } from 'gatsby';
import fetch from 'isomorphic-fetch';

function encode(data: any) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

const ContactForm: React.FunctionComponent = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e: any): void => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const handleSubmit = (e: any): void => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
          }),
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error: any) => alert(error))
      }
      
    return (
        <form name="contact" method="post" data-netlify="true" action="/tak/" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Dit navn
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 h-12" type="text" name="name" onChange={handleChange} />
            </div>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 h-12" type="email" name="email" onChange={handleChange} />
            </div>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Tlf. nummer
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 h-12" type="text" name="phone" onChange={handleChange} />
            </div>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Besked
                </label>
                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4" rows={8} name="message" onChange={handleChange} />
            </div>
            <br></br>
            <div className="text-center">
                <button className="internal-button button-text py-3 px-6 rounded inline-flex items-center" type="submit">Indsend</button>
            </div>
        </form>
    );
}

export default ContactForm;