import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import SmallContainer from '../components/layout/SmallContainer';
import WideContainer from '../components/layout/WideContainer';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import ContactForm from '../components/forms/ContactForm';

const KontaktPage: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <MetaData title="Kontakt" />
      <OrganizationStructuredData />
      <div className="bg-white">
        <SmallContainer className="pt-4">
          <h1 className="text-center">Kontakt</h1>
          <hr className="w-48 mx-auto mb-6 border-2" />
          <p className="mb-8">
              Her kan du skrive til os. Du kan bare skrive om samarbejde eller sende os dine egne opskrifter.
              Vi vil altid gerne høre fra dig.
          </p>
          <ContactForm />
        </SmallContainer>
      </div>
    </Layout>
  );
};

export default KontaktPage;